body, html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  height: 100%;
  font-family: Arial, sans-serif;
}





.title {
  font-size: 48px; /* Adjust the font size as needed */
  padding-top: 20px;
  padding-bottom: 20px;
}

.first-word {
  color: rgb(0, 174, 255); /* Color for the first word */
}

.second-word {
  color: white; /* Color for the second word */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}






.nav-layer {
  z-index: 1000;
}

.nav {
  min-width: min-content;
  top: 0;
  min-height: 45px;
  padding: 5px;
  width: 100%;
  background: rgb(52, 52, 52);
  display: flex;
  justify-content: center;
  z-index: 1000;
  max-height: 45px;
  position: absolute;
}

.nav-buttons {
  display: flex;
  justify-content: space-between; 
  width: 50%;
}

.nav-buttons button {
  background-color: rgba(50, 50, 50, 0.5);
  color: white;
  border: black;
  padding: 1vh 2vw;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 2px 5px 5px rgba(0, 0, 0, 1);
  min-width: min-content;
  min-height: min-content;
}

.nav-buttons button:hover {
  background-color: #383838;
}

.custom-shape-divider-top-1717945405 {
  min-width: min-content;
  min-height: min-content;
  position: absolute;
  top: 55px;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1717945405 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 49px;
}

.custom-shape-divider-top-1717945405 .shape-fill {
  fill: #343434;
}








.para1,.para2,.para3, .contentDiv, .para4{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.para1 {
  background-color: rgb(34, 34, 34);
  height: 85vh;
}

.contentDiv {
  position: relative;
  width: 50%;
  left: 25%;
}




.para4 {
  background: linear-gradient(rgb(8,8,8), rgb(52, 52, 52));
  height: 200vh;
}




.para2 {
  background-color: rgb(17, 17, 17);
  height: 200vh;
}

.custom-shape-divider-top-1717947413 {
  position: absolute;
  top: -5;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
  z-index: 50;
}

.custom-shape-divider-top-1717947413 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 66px;
  transform: rotateY(180deg);
}

.custom-shape-divider-top-1717947413 .shape-fill {
  fill: #222222;
}

.title-p2 {
  position: absolute;
  top: 5%;
  left: 5%;
  margin: 20px;
  padding: 10px;
  font-size: 40px;
}

.title-p3 {
  position: absolute;
  top: 10%;
  left: 5%;
  margin: 20px;
  padding: 10px;
  font-size: 40px;
}



.sticky-box{
  position: relative;
  padding: 20px;
  background-color: white; /* Adjust as needed */
  box-shadow: 2px 7.5px 7.5px rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  z-index: 10;
  min-width: min-content;
  min-height: min-content;
  max-width: 15%;
  height: auto;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.para3 {
  background-color: rgb(8, 8, 8);
  height: 250vh;
}

.custom-shape-divider-top-1717948327 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.custom-shape-divider-top-1717948327 svg {
  position: relative;
  display: block;
  width: calc(100% + 1.3px);
  height: 103px;
}

.custom-shape-divider-top-1717948327 .shape-fill {
  fill: #111111;
}





/* Style for the box */
.box {
  width: 200px;
  height: 200px;
  background-color: rgba(50, 50, 50, 0.5);
  border-radius: 20px;
  display: flex; 
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 1);
}


.wide-box {
  min-width: min-content;
  min-height: min-content;
  padding: 20px;
  max-width: 100%;
  max-height: 100%; 
  background-color: rgba(50, 50, 50, 0.5);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: whitesmoke;
  box-shadow: 2px 10px 10px rgba(0, 0, 0, 1);
}

.img-Box {
  margin-left: 10%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: auto;
  max-height: 30%;
  width: 25vw;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  background-color: whitesmoke;
  box-shadow: 2px 5px 5px rgba(255, 255, 255, 0.5);
}

.box-img {
  width: auto; /* Adjust this value as needed */
  height: 80%;
  margin-right: 10px;
  padding: 10px;
}
p {
  width: 50%; /* Adjust this value as needed */
  font-size: 1em;
}

.bubble-text{
  align-items: center;
  width: 75%;
  text-align: center;
  padding: 10px;
}




/* animation effect */
.flashing-underscore {
  display: inline-block;
  position: relative;
}

.flashing-underscore::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 2px; /* Height of the underscore */
  background-color: white;
  animation: flashing-underscore-animation 1s infinite alternate; /* Animation duration and type */
}

@keyframes flashing-underscore-animation {
  0% {
    opacity: 1; /* Fully visible */
  }
  50% {
    opacity: 0; /* Fully transparent */
  }
  100% {
    opacity: 1; /* Fully visible */
  }
}





.skill-box {
  position: relative;
  padding: 10px;
  background: linear-gradient(to bottom right, rgb(34, 34, 34), rgb(55, 55, 55));
  border-radius: 10px;
  z-index: 10;
  width: 30vw; 
  max-width: 200px;
  height: auto; 
  display: flex;
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  min-height: min-content;
  color: whitesmoke;
  box-shadow: 2px 2px 5px rgb(177, 175, 175);
}
.skill-box h3 {
  font-size: 1em;
}
.skill-box ul {
  margin-right: 10px;
  font-size: 0.8em;
}
.skill-box ul li {
  margin-bottom: 5px; /* Space between list items */
}



.icon-box {
  position: relative;
  width: 10%;
  height: auto;
  min-height: min-content;
  padding: 20px;

  background: linear-gradient(to bottom right, rgb(0, 174, 255), rgb(34, 34, 34));
  border-radius: 10px;
  z-index: 10;
  color: whitesmoke;
  box-shadow: 2px 2px 5px rgb(177, 175, 175);
  box-sizing: border-box;
  display: flex;
  flex-direction: column; 
  justify-content: flex-start; 
  align-items: flex-start; 
  overflow: hidden; 
}

.icon-box .icon {
  width: 15px; 
  height: 15px; 
  margin-bottom: 5px; 
}

.icon-box h3 {
  margin: 0 0 10px 0; 
  font-size: 1em; 
}

.icon-box p {
  margin: 0; 
  font-size: 0.5em; 
}


.img-Box-wide{
  margin-left: 10%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  height: auto;
  max-height: 30%;
  width: 25vw;
  border: 1px solid #ddd;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  padding: 10px;
  background-color: whitesmoke;
  box-shadow: 2px 5px 5px rgba(255, 255, 255, 0.5);
}

.badge-box {
  width: min-content;
  height: 10vh;
  background-color: whitesmoke;
  border-radius: 10px;
  box-shadow: 2px 5px 5px rgba(255, 255, 255, 0.5);
  display: flex;
  align-content: center;
  justify-content: center;
  padding: 10px;
}

.icon {
  width: auto;
  height: 80%;
  padding: 10%;
}

.proj-layer {
  min-height: 50vh; /* Ensure each layer covers at least half the screen height */
  z-index: 5;
}

.project-box {
  position: relative;
  padding: 20px;
  background: linear-gradient(to bottom right, rgb(0, 174, 255), whitesmoke);
  border-radius: 10px;
  z-index: 10;
  width: 40vw;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: black;
  box-shadow: 2px 2px 5px rgb(177, 175, 175);
}

.project-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
  z-index: 10;
}

.project-description {
  margin-top: 20px;
  z-index: 10;
}

.github-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 200;
  padding: 5px;
}

.github-icon {
  width: 50px; /* Adjust size as needed */
  height: 48px; /* Adjust size as needed */
}

/* mobile phones */
@media (max-width: 480px) {
  .sticky-box {
    max-width: 90%; /* Adjust based on design requirements */
    padding: 10px;
  }
  
  .box-img {
    width: 80%;
  }
  .skill-box {
    width: 10%;
  }

  .bubble-text, .sticky-box p {
    font-size: 0.5em;
    width: 100%;
  }

  .icon-box {
    max-width: 80%;
    padding: 8px;
  }
  
  .icon-box h3 {
    font-size: 1em;
  }

  .icon-box p {
    font-size: 0.8em;
  }

  .project-box {
    flex-direction: column;
    align-items: center;
  }

  .project-description {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    text-align: center;
    margin-top: 10px;
  }

  .github-button {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 10px;
  }

  .project-image {
    max-width: 100%;
    max-height: 30vh;
    margin-right: 0;
  }

  .project-box {
    width: 60vw;
  }

  .project-image {
    max-width: 40%;
  }

  .project-description {
    font-size: 0.5em;
  }
}

/* Tablet (481px to 768px) */
@media (min-width: 481px) and (max-width: 768px) {
  .sticky-box {
    max-width: 10%; /* Adjust based on design requirements */
    padding: 15px;
  }

  .img-Box {
    font-size: 0.5em;
    padding: 5%;
  }
  
  .box-img {
    width: 40%;
    
  }

  .bubble-text, .sticky-box p {
    font-size: 0.6em;
    width: 100%;
  }

  .badge-box{
    max-width: 10%;
  }

  .skill-box {
    font-size: 0.5em;
    max-width: min-content;
    padding: 5px;
  }

  .skill-box h3 {
    font-size: 0.75em;
  }

  .skill-box ul {
    font-size: 0.5em;
  }

  .icon-box {
    max-width: 30%;
    max-height: min-content;
    padding: 15px;
  }
  
  .icon-box h3 {
    font-size: 1em;
  }

  .icon-box p {
    font-size: 0.6em;
  }


  .project-box {
    width: 30vw;
  }

  .project-image {
  }

  .project-description {
    font-size: 0.75em;
  }

  
  
}



/* Desktop (769px and above) */
@media (min-width: 769px) {
  .sticky-box {
    max-width: 20%; /* Adjust based on design requirements */
    padding: 20px;
    height: 15%;
  }
  
  .img-Box{
    min-width: min-content;
    max-height: min-content;
    padding: 20px;
  }

  .box-img {
    width: 50%;
    max-height: 15vh;
  }

  .bubble-text, .sticky-box p {
    font-size: 1em;
    width: 100%;
    height: auto;
  }

  .icon-box {
    max-width: 30%;
    max-height: min-content;
    padding: 15px;
  }
  
  .icon-box h3 {
    font-size: 1em;
  }

  .icon-box p {
    font-size: 0.6em;
  }

  .project-box {
    width: 30vw;
  }

  .project-description {
    word-wrap: break-word;
    font-size: 1em;
  }

}